import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useDragAndDropStore = defineStore('drag-and-drop-store', () => {
    const dragging = ref(false);

    return {
        dragging
    };
});

