// composables/useSelectable.js
import { computed } from 'vue';

export function useSelectable(store, id) {
    const isSelected = computed(() => { return store.isSelected(id); })

    const handleClick = (event) => {
        if (event.ctrlKey || event.metaKey) {
            // Ctrl-click or Cmd-click: toggle selection
            store.toggleSelection(id);
        } else {
            // Regular click: clear selection and select the current id
            store.clearSelection();
            store.toggleSelection(id);
        }
    };
    return {
        isSelected,
        handleClick
    };
}
